import jQuery from "jquery";
import * as bootstrap from 'bootstrap';
const $ = jQuery;
window.$ = $;

export function base() {

    const minutesRafraichissementDonnees = 5
    const msRafraichissementDonnees = minutesRafraichissementDonnees * 60000    

    const assurerAuthentification = async() => {
        
        localStorage.removeItem("idPooler")

        const idPooler = await obtenirIdPoolerCourantServeur()
        if (idPooler === '0') {

            if(location.hostname === "localhost"){
                const redirectUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/RetourLogin?redirectUrl=${window.location.pathname}`  
                window.location.href = redirectUrl
            } else {
                const redirectUrl = `${window.location.protocol}//${window.location.hostname}/RetourLogin?redirectUrl=${window.location.pathname}`  
                window.location.href = `/.auth/login/aad?post_login_redirect_uri=${redirectUrl}`
            }
        }

        localStorage.setItem("idPooler", idPooler)
        return idPooler
    };

    const obtenirIdPoolerCourant = async(force = false) => {
        if (force) {
            return await rafraichirIdPoolerCourant()
        } else {
            const idPooler = localStorage.getItem("idPooler")
            if (idPooler) {
                return idPooler
            } else {
                return await rafraichirIdPoolerCourant()
            }
        }
    };

    const rafraichirIdPoolerCourant = async() => {
        const idPooler = await obtenirIdPoolerCourantServeur()
        localStorage.setItem("idPooler", idPooler)
        return idPooler
    }

    const obtenirIdPoolerCourantServeur = async() => {

        const reponse = await fetch("/api/ObtenirUtilisateurCourant")
        let utilisateur = {}

        if (reponse.ok) { // if HTTP-status is 200-299        
            utilisateur = await reponse.json();
            return utilisateur.idPooler
        } else {
            //TODO Traitement des erreurs
            alert("HTTP-Error: " + reponse.status);
            return 0
        }
    }

    const obtenirDonnees = async(force = false, cle, nomFichierJson) => {

        const dateDerniereMaj = localStorage.getItem(`${cle}.dateDerniereMaj`)

        if (dateDerniereMaj) {
            if (force || Date.now() - dateDerniereMaj > msRafraichissementDonnees) {
                return await rafraichirDonnees(cle, nomFichierJson)
            } else {
                //La valeur du localstorage est toujours valide
                const donnees = localStorage.getItem(cle)
                if (donnees) {
                    return JSON.parse(donnees)
                } else {
                    return await rafraichirDonnees(cle, nomFichierJson)
                }
            }
        } else {
            return await rafraichirDonnees(cle, nomFichierJson)
        }
    }


    const rafraichirDonnees = async(cle, nomFichierJson) => {
        const valeur = await obtenirDonneesFichier(nomFichierJson)
        majDonneesLocalStorage(cle, valeur)
        return valeur
    }

    const obtenirDonneesFichier = async(nomFichierJson) => {
        //L'url contient un cache buster pour forcer un rechargement du fichier peut importe la cache définie puisqu'on gère ça nous même avec le localstorage
        const url = `https://poolombier.blob.core.windows.net/json/${nomFichierJson}.json?${Date.now()}`

        let donnees = [];
        const reponse = await fetch(url);

        if (reponse.ok) { // if HTTP-status is 200-299        
            donnees = await reponse.json();

        } else {
            //TODO Traitement des erreurs
            alert("HTTP-Error: " + reponse.status);
        }

        return donnees;
    }

    const majDonneesLocalStorage = (cle, valeur) => {
        localStorage.setItem(`${cle}.dateDerniereMaj`, Date.now())
        localStorage.setItem(cle, JSON.stringify(valeur))
    }

    const groupArrayBy = (array, groupBy) => {

        return array.reduce((acc, value) => {

            // Group initialization
            if (!acc[value[groupBy]]) {
                acc[value[groupBy]] = [];
            }

            // Grouping
            acc[value[groupBy]].push(value)

            return acc;
        }, {})
    }

    const definirDataTablesJoueurs = (selecteurTable, options) => {

        let optionsDT = {
            "paging": false,
            "info": false,
            "searching": false,
            "order-column": false,
            "language": obtenirTraductionDatatablesFrancais(),
            responsive: {
                details: {
                    type: 'column',
                    target: 'td.control'
                }
            },
            columnDefs: [{
                    className: 'control',
                    orderable: false,
                    width: "20px",
                    targets: -1
                },
                { "type": "numeric-comma", "targets": 6 },
                { orderable: false, targets: 7 },
                { orderable: false, targets: 8 },
                { orderable: false, targets: 9 },
                { orderable: false, targets: 10 },
                { orderable: false, targets: 11 },
                { orderable: false, targets: 12 },
                { orderable: false, targets: 13 },
                { responsivePriority: 1, targets: 0 },
                { responsivePriority: 2, targets: 1 },
                { responsivePriority: 6, targets: 2 },
                { responsivePriority: 7, targets: 3 },
                { responsivePriority: 5, targets: 4 },
                { responsivePriority: 4, targets: 5 },
                { responsivePriority: 3, targets: 6 },
                { responsivePriority: 5, targets: 7 }
            ],
            "order": [
                [1, "asc"],
                [6, "desc"]
            ]
        }

        $.extend(optionsDT, options)
        $(selecteurTable).DataTable(optionsDT);

        $(selecteurTable).on('page.dt', function() {
            activerPopoversStatut()
        })
    }

    const activerPopoversStatut = () => {
        //Activer les popovers (statut)
        setTimeout(() => {
            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            popoverTriggerList.map(function(popoverTriggerEl) {
                return new bootstrap.Popover(popoverTriggerEl)
            });
        }, 200);
    }

    const obtenirTraductionDatatablesFrancais = () => {
        return {
            "emptyTable": "Aucune donnée disponible dans le tableau",
            "lengthMenu": "Afficher _MENU_ éléments",
            "loadingRecords": "Chargement...",
            "processing": "Traitement...",
            "zeroRecords": "Aucun élément correspondant trouvé",
            "paginate": {
                "first": "Premier",
                "last": "Dernier",
                "previous": "Précédent",
                "next": "Suiv"
            },
            "aria": {
                "sortAscending": ": activer pour trier la colonne par ordre croissant",
                "sortDescending": ": activer pour trier la colonne par ordre décroissant"
            },
            "select": {
                "rows": {
                    "_": "%d lignes sélectionnées",
                    "0": "Aucune ligne sélectionnée",
                    "1": "1 ligne sélectionnée"
                },
                "1": "1 ligne selectionnée",
                "_": "%d lignes selectionnées",
                "cells": {
                    "1": "1 cellule sélectionnée",
                    "_": "%d cellules sélectionnées"
                },
                "columns": {
                    "1": "1 colonne sélectionnée",
                    "_": "%d colonnes sélectionnées"
                }
            },
            "autoFill": {
                "cancel": "Annuler",
                "fill": "Remplir toutes les cellules avec <i>%d<i>",
                "fillHorizontal": "Remplir les cellules horizontalement",
                "fillVertical": "Remplir les cellules verticalement",
                "info": "Exemple de remplissage automatique"
            },
            "searchBuilder": {
                "conditions": {
                    "date": {
                        "after": "Après le",
                        "before": "Avant le",
                        "between": "Entre",
                        "empty": "Vide",
                        "equals": "Egal à",
                        "not": "Différent de",
                        "notBetween": "Pas entre",
                        "notEmpty": "Non vide"
                    },
                    "number": {
                        "between": "Entre",
                        "empty": "Vide",
                        "equals": "Egal à",
                        "gt": "Supérieur à",
                        "gte": "Supérieur ou égal à",
                        "lt": "Inférieur à",
                        "lte": "Inférieur ou égal à",
                        "not": "Différent de",
                        "notBetween": "Pas entre",
                        "notEmpty": "Non vide"
                    },
                    "string": {
                        "contains": "Contient",
                        "empty": "Vide",
                        "endsWith": "Se termine par",
                        "equals": "Egal à",
                        "not": "Différent de",
                        "notEmpty": "Non vide",
                        "startsWith": "Commence par"
                    },
                    "array": {
                        "equals": "Egal à",
                        "empty": "Vide",
                        "contains": "Contient",
                        "not": "Différent de",
                        "notEmpty": "Non vide",
                        "without": "Sans"
                    }
                },
                "add": "Ajouter une condition",
                "button": {
                    "0": "Recherche avancée",
                    "_": "Recherche avancée (%d)"
                },
                "clearAll": "Effacer tout",
                "condition": "Condition",
                "data": "Donnée",
                "deleteTitle": "Supprimer la règle de filtrage",
                "logicAnd": "Et",
                "logicOr": "Ou",
                "title": {
                    "0": "Recherche avancée",
                    "_": "Recherche avancée (%d)"
                },
                "value": "Valeur"
            },
            "searchPanes": {
                "clearMessage": "Effacer tout",
                "count": "{total}",
                "title": "Filtres actifs - %d",
                "collapse": {
                    "0": "Volet de recherche",
                    "_": "Volet de recherche (%d)"
                },
                "countFiltered": "{shown} ({total})",
                "emptyPanes": "Pas de volet de recherche",
                "loadMessage": "Chargement du volet de recherche..."
            },
            "buttons": {
                "copyKeys": "Appuyer sur ctrl ou u2318 + C pour copier les données du tableau dans votre presse-papier.",
                "collection": "Collection",
                "colvis": "Visibilité colonnes",
                "colvisRestore": "Rétablir visibilité",
                "copy": "Copier",
                "copySuccess": {
                    "1": "1 ligne copiée dans le presse-papier",
                    "_": "%ds lignes copiées dans le presse-papier"
                },
                "copyTitle": "Copier dans le presse-papier",
                "csv": "CSV",
                "excel": "Excel",
                "pageLength": {
                    "-1": "Afficher toutes les lignes",
                    "1": "Afficher 1 ligne",
                    "_": "Afficher %d lignes"
                },
                "pdf": "PDF",
                "print": "Imprimer"
            },
            "decimal": ",",
            "info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
            "infoThousands": ".",
            "search": "Rechercher:",
            "searchPlaceholder": "",
            "thousands": ".",
            "infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
            "datetime": {
                "previous": "Précédent",
                "next": "Suivant",
                "hours": "Heures",
                "minutes": "Minutes",
                "seconds": "Secondes",
                "unknown": "-",
                "amPm": [
                    "am",
                    "pm"
                ]
            },
            "editor": {
                "close": "Fermer",
                "create": {
                    "button": "Nouveaux",
                    "title": "Créer une nouvelle entrée",
                    "submit": "Envoyer"
                },
                "edit": {
                    "button": "Editer",
                    "title": "Editer Entrée",
                    "submit": "Modifier"
                },
                "remove": {
                    "button": "Supprimer",
                    "title": "Supprimer",
                    "submit": "Supprimer"
                },
                "error": {
                    "system": "Une erreur système s'est produite"
                },
                "multi": {
                    "title": "Valeurs Multiples",
                    "restore": "Rétablir Modification"
                }
            }
        }
    }

    const estAdministrateur = async() => {
        const idUtilisateur = await obtenirIdPoolerCourant()
        return idUtilisateur === '0000001' || idUtilisateur === '0000003' 
    }

    const genererId = () => {
        return Date.now().toString(36) + '-' + Math.random().toString(36).substr(2, 9);
    }

    return {
        obtenirIdPoolerCourant,
        obtenirDonnees,
        groupArrayBy,
        definirDataTablesJoueurs,
        obtenirTraductionDatatablesFrancais,
        activerPopoversStatut,
        estAdministrateur,
        genererId,
        assurerAuthentification
    }
}