<template>
  <div class="container">
    <div class="entete-page">
        <h1 class="titre-page">Choix de repêchage 2024</h1>
    </div>
    <div class="contenu-principal-page">
        <div class="d-flex flex-wrap">
          <ChoixRepechage></ChoixRepechage>            
        </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import ChoixRepechage from '../components/ChoixRepechage.vue';

export default {
  name: "Picks",
  components: {
    ChoixRepechage
  },
  setup() {   
   
    onMounted(async () => {
 
    });

    return {
 
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
